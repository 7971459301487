import apiBase from "../apiBase";

export default {
    // PRODUCT
    createProduct(product) {
        const url = "api/sale/product/create";
        return apiBase.post(url, product);
    },
    updateProduct(product) {
        const url = "api/sale/product/update";
        return apiBase.put(url, product);
    },
    deleteProduct(product_id) {
        const url = "api/sale/product/delete/" + product_id;
        return apiBase.delete(url);
    },
    getProducts(page = 1, names = "") {
        if (page < 1) page = 1;
        const url = "api/sale/product/getProducts?page=" + page + "&&names=" + names;
        return apiBase.get(url);
    },
    getProductsPaginated(page = 1, names = "") {
        if (page < 1) page = 1;
        const url = "api/sale/product/getProductsPaginated?page=" + page + "&&names=" + names;
        return apiBase.get(url);
    },
    getProductsDropdown(page = 1, names = "") {
        if (page < 1) page = 1;
        const url = "api/sale/product/getProductsDropdown?page=" + page + "&&names=" + names;
        return apiBase.get(url);
    },
    getProductsWithStockDropdown(page = 1, names = "") {
        if (page < 1) page = 1;
        const url = "api/sale/product/getProductsWithStockDropdown?page=" + page + "&&names=" + names;
        return apiBase.get(url);
    },
    getCategoriesDropdown(page = 1, names = "") {
        if (page < 1) page = 1;
        const url = "api/sale/category/getCategoriesDropdown?page=" + page + "&&names=" + names;
        return apiBase.get(url);
    },
    getPresentationsDropdown(page = 1, names = "") {
        if (page < 1) page = 1;
        const url = "api/sale/presentation/getPresentationsDropdown?page=" + page + "&&names=" + names;
        return apiBase.get(url);
    },
    getLaboratoriesDropdown(page = 1, names = "") {
        if (page < 1) page = 1;
        const url = "api/sale/laboratory/getLaboratoriesDropdown?page=" + page + "&&names=" + names;
        return apiBase.get(url);
    },
};
