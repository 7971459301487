import React, { useEffect, useState } from "react";
import apiDinner from "../../../apis/dinner/apiDinner";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";
import AppLoading from "../../common/AppLoading";
import { Link, useHistory } from "react-router-dom";
import BreadCrumb from "../../common/AppBreadCrumb";
import appColors from "../../../config/appColors";
import AppAlert from "../../common/AppAlert";

function ListDinnersScreen() {
	const headers = ["Fecha", "Nombre comedor"];
	const values = ["created", "name"];
	const buttons = [
		{ onClick: (dinner) => EditSelectedDinner(dinner), style: "btn", iconName: "pen", iconColor: appColors.green_04 },
		{ onClick: (dinner) => ShowDeleteSelectedDinner(dinner), style: "btn", iconName: "trash-alt", iconColor: appColors.red_04 },
	];

	const history = useHistory();
	const [filter, setFilter] = useState("");
	const [paginator, setPaginator] = useState({});
	const [dinners, setDinners] = useState([]);
	const [loading, setLoading] = useState(false);

	const GetDinners = async (page = 1) => {
		setLoading(true);
		var response = await apiDinner.getDinnersPaginated(page, filter);
		setLoading(false);

		if (response.ok) {
			setPaginator(response.data.paginator);
			setDinners(response.data.results);
		}
	};

	const EditSelectedDinner = (dinner) => {
		history.push({ pathname: "/dinner/edit", state: dinner });
	};

	const ShowDeleteSelectedDinner = (dinner) => {
		AppAlert.OptionsAlert(
			"Este comedor se eliminará, y ya no estará disponible para el resto de operaciones, y se eliminará toda su información.",
			"Eliminar de todos modos"
		).then((response) => {
			if (response) {
				DeleteDinner(dinner.id);
			}
		});
	};

	const DeleteDinner = async (dinner_id) => {
		var response = await apiDinner.deleteDinner(dinner_id);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			GetDinners();
		}
	};

	useEffect(() => {
		GetDinners();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-10">
				<AppTitle text="Lista de comedores registrados:" />
				<BreadCrumb items={[{ label: "Comedores" }, { label: "Lista de Come	dores Registrados", active: true }]} />
			</div>
			{/*<div className="col-2 d-flex align-items-center">*/}
			{/*	<Link className="btn btn-sm btn-success" to={"dinner/create"}>*/}
			{/*		Registrar Comedor*/}
			{/*	</Link>*/}
			{/*</div>*/}
			<div className="col-12">
				<TablePaginate
					buttons={buttons}
					headers={headers}
					rows={dinners}
					values={values}
					onSearchApi={(page) => GetDinners(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
				/>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default ListDinnersScreen;
