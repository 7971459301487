import apiSale from "../../../apis/sale/apiSale";
import React, {useEffect, useRef, useState} from "react";
import AppAlert from "../../common/AppAlert";
import apiPerson from "../../../apis/person/apiPerson";
import AppSelect from "../../controls/AppSelect";
import helperDate from "../../../helpers/helperDate";
import AppClock from "../../controls/AppClock";
import AppLoading from "../../common/AppLoading";
import AppTitle from "../../controls/AppTitle";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import apiProduct from "../../../apis/product/apiProduct";
import AppSubTitle from "../../controls/AppSubTitle";
import helperSession from "../../../helpers/helperSession";
import appAlert from "../../common/AppAlert";
import {useReactToPrint} from "react-to-print";
import PrintPaymentProofBoleta from "../forms/PrintPaymentProofBoleta";
import PrintPaymentProofFactura from "../forms/PrintPaymentProofFactura";
import AppInput from "../../controls/AppInput";
import apiPayment from "../../../apis/payment/apiPayment";
import apiDocument from "../../../apis/document/apiDocument";
import AppIcon from "../../controls/AppIcon";

function SaleCreateScreen() {
    const [invoiceCode, setInvoiceCode] = useState();
    const [person, setPerson] = useState({});
    const [totalAmount, setTotalAmount] = useState(0);
    const [personList, setPersonList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [documentList, setDocumentList] = useState({items: []});
    const [paymentList, setPaymentList] = useState([]);
    const [documentSelected, setDocumentSelected] = useState({});
    const [paymentSelected, setPaymentSelected] = useState({});
    const [productListSelected, setProductListSelected] = useState({items: []});
    const [personListToSearch, setPersonListToSearch] = useState([]);

    const [isBoleta, setIsBoleta] = useState(true);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const componentRefBoleta = useRef();
    const componentRefFactura = useRef();

    useEffect(() => {
        GetAllPersons();
        GetAllProducts();
        GetPaymentTypes();
        GetDocumentTypes();
        return () => {
        };
    }, []);

    const SendToPrintFormBoleta = useReactToPrint({
        content: () => componentRefBoleta.current,
    });

    const SendToPrintFormFactura = useReactToPrint({
        content: () => componentRefFactura.current,
    });

    const GetAllProducts = async () => {
        let response = await apiProduct.getProductsWithStockDropdown();

        if (response.ok) {
            setProductList(response.data);
        }
    };

    const GetPaymentTypes = async () => {
        let response = await apiPayment.getPaymentTypesDropdown();

        if (response.ok) {
            setPaymentList(response.data);
        }
    };

    const GetDocumentTypes = async () => {
        let response = await apiDocument.getDocumentTypesDropdown();

        if (response.ok) {
            let items = [];
            response.data?.map((item, index) => {
                let localItem = item;

                if (index === 0) {
                    localItem.checked = true
                    setDocumentSelected(localItem);
                } else {
                    localItem.checked = false
                }
                items.push(localItem);
            })
            setDocumentList({...documentList, items: items});
        }
    };

    const GetAllPersons = async () => {
        let response = await apiPerson.getPersonsDropdown();
        if (response.ok) {
            setPersonList(response.data);
        }
    };

    const CreateSale = async () => {
        setLoading(true);
        let personResponse = await apiPerson.createOrUpdatePerson(person);
        setLoading(false);

        if (personResponse.ok) {
            setPerson(personResponse.data);
            setErrors({});
        } else {
            setErrors(personResponse);
            return;
        }

        const sale = {
            saled_date: helperDate.getCurrentToServer(),
            user_id: helperSession.getCurrentUser().id,
            user: helperSession.getCurrentUser(),
            products: productListSelected.items,
            person_id: personResponse.data?.id ? personResponse.data?.id : null,
            person: personResponse.data ? personResponse.data : {},
            document_type_id: documentSelected.value,
            document_type_name: documentSelected.label,
            payment_type_id: paymentSelected.value,
            payment_type_name: paymentSelected.label,
            amount_final: Math.round((totalAmount * (1 + parseFloat(paymentSelected.tax ? (paymentSelected.tax / 100) : 0))) * 100) / 100,
            commission: parseFloat(paymentSelected.tax ? (paymentSelected.tax / 100) : 0),
        };

        setLoading(true);
        const response = await apiSale.createSale(sale);
        setLoading(false);

        if (response.ok) {
            setInvoiceCode(response.data?.correlative)

            // apiInvoice.SendInvoiceDocument(response.data).then((invoiceResponse) => {
            //     if (invoiceResponse.ok) {
            AppAlert.OptionsAlert(`${response.message} ¿ Desea imprimir su comprobante ?`, 'Imprimir', 'warning', '').then((accepted => {
                if (accepted) {
                    setInvoiceCode(response.data?.correlative)

                    if (isBoleta) SendToPrintFormBoleta();
                    else SendToPrintFormFactura();
                }
            }));
            //     }
            // });
        } else {
            setErrors(response);
        }
    };
    const ChangeDocumentType = (document) => {
        let localArray = [];

        documentList.items.map((item, index) => {
            let localItem = item;
            localItem.checked = item.value === document.value;
            localArray.push(localItem);
        });

        setDocumentList({...documentList, items: localArray})
        setDocumentSelected(document);
        setIsBoleta(document.label.toString().toUpperCase() === 'BOLETA');
    };

    const onInputChange = (value = "") => {
        if (value && value.length > 0) {
            let persons = personList;
            const filteredPersons = persons.filter((x) => x.label.toUpperCase().includes(value.toUpperCase()));
            setPersonListToSearch(filteredPersons);
        } else {
            setPersonListToSearch([]);
        }
    };

    const SelectPerson = async (person = {}) => {
        if (person && person.value) {
            setLoading(true);
            const response = await apiPerson.getPersonByID(person.value)
            setLoading(false);

            if (response.ok)
                setPerson(response.data)
            else
                setPerson({});
        }
    };


    const onSetProductListSelected = (products = []) => {
        let productsList = products.map((product) => {
                let foundedElement = productListSelected.items.find(x => x.id === product.value);
                if (foundedElement) {
                    return {
                        name: product.label,
                        id: product.value,
                        price: product.price,
                        unit_price: product.price,
                        stock: product.stock,
                        quantity: foundedElement.quantity
                    }
                } else {
                    return {
                        name: product.label,
                        id: product.value,
                        price: product.price,
                        unit_price: product.price,
                        stock: product.stock,
                        quantity: 1
                    }
                }
            }
        );
        setProductListSelected({...productListSelected, items: productsList});
        CalculateAmountToPay(productsList);
    };

    const CalculateAmountToPay = (productListSelected) => {
        let totalPrice = 0;

        for (let index = 0; index < productListSelected.length; index++) {
            totalPrice += productListSelected[index].price * productListSelected[index].quantity;
        }
        totalPrice = totalPrice.toFixed(2);
        setTotalAmount(totalPrice);
    };

    const onInputQuantityChange = (product_id, value) => {
        let list = productListSelected.items;
        const product = list.find((x) => x.id === product_id);

        if (product) {
            const productIndex = list.findIndex((x) => x.id === product_id);

            if (parseInt(value) > product.stock) {

                if ((parseInt(value) - product.stock) === 1)
                    product.quantity = product.stock.toString();
                else {
                    product.quantity = value.toString();
                }
            } else {
                product.quantity = value.toString();
            }

            list.splice(productIndex, 1, product);
            setProductListSelected({...productListSelected, items: list});
            CalculateAmountToPay(list);
        }

    };

    return (
        <div className="row">
            <div className="col-12">
                <AppTitle text="Registro de Ventas:"/>
                <AppBreadCrumb items={[{label: "Ventas"}, {label: "Registro de Venta:", active: true}]}/>
            </div>
            <div className="col-12 shadow">
                {/* ************ CUSTOMER ************/}
                <div className="row card-body justify-content-center align-items-center">
                    <div className="col-12 form-group text-center">
                        <label htmlFor="exampleInputtext1">
                            <b>HORA Y FECHA DE VENTA</b>
                        </label>
                        <AppClock/>
                    </div>
                    <div className="row col-sm-12">
                        <div className='col-12 flex-grow-1 mb-2'>
                            <AppSubTitle text="DATOS DEL CLIENTE: "/>
                        </div>
                        <div className=" shadow p-4 mr-1 col-sm-4 justify-content-centeral align-content-center">
                            <AppSelect errors={errors.person_id} label="Buscar por Nombre/Dni: "
                                       onInputChange={onInputChange}
                                       onChange={(person) => {
                                           SelectPerson(person)
                                       }} items={personListToSearch}/>
                            <br/>
                            <view className='flex-grow-1 row justify-content-center align-items-center'>
                                <AppIcon iconName='exclamation-circle' color='green'/>
                                <span className="font-weight-light text-success small">Búsqueda del cliente por nombre o Dni</span>
                            </view>
                        </div>
                        <div className="row col-sm-8 ml-1 shadow text-center p-4 card-body">
                            <div className='col-6'>
                                <AppInput disabled={false} value={person.first_names} onChange={({target}) => {
                                    setPerson({...person, first_names: target.value})
                                }} placeholder={'Nombres'} errors={errors.first_names}
                                          label='Nombres:'/>
                            </div>
                            <div className='col-6'>
                                <AppInput disabled={false} value={person.last_names} onChange={({target}) => {
                                    setPerson({...person, last_names: target.value})
                                }} placeholder={'Apellidos'} errors={errors.last_names}
                                          label='Apellidos:'/>
                            </div>
                            <div className='col-4'>
                                <AppInput disabled={false} value={person.dni} onChange={({target}) => {
                                    setPerson({...person, dni: target.value})
                                }} placeholder='DNI' label='DNI:' errors={errors.dni}/>
                            </div>
                            <div className='col-4'>
                                <AppInput disabled={false} value={person.ruc} onChange={({target}) => {
                                    setPerson({...person, ruc: target.value})
                                }} placeholder='RUC' label='RUC:' errors={errors.ruc}/>
                            </div>
                            <div className='col-4'>
                                <AppInput disabled={false} value={person.phone} onChange={({target}) => {
                                    setPerson({...person, phone: target.value})
                                }} placeholder='Celular' label='Celular:' errors={errors.phone}/>
                            </div>
                            <div className='col-12'>
                                <AppInput disabled={false} value={person.address} onChange={({target}) => {
                                    setPerson({...person, address: target.value})
                                }} placeholder='Direccion' label='Dirección:' errors={errors.address}/>
                            </div>
                            <view className='flex-grow-1 row justify-content-center align-items-center'>
                                <AppIcon iconName={'exclamation-circle'} color='green'/>
                                <span className="font-weight-light text-success small">Permite registrar/modificar el cliente ingresado/buscado</span>
                            </view>
                        </div>
                    </div>
                </div>

                {/* ************ PRODUCTS ************/}
                <div className="row card-body justify-content-center align-items-center">
                    <div className='col-12 flex-grow-1 mb-2'>
                        <AppSubTitle text="PRODUCTOS, CANTIDAD Y TIPO DE DOCUMENTO: "/>
                    </div>

                    <div className="row col-12">

                        <div className="px-4 col-md-6 shadow">
                            <AppSubTitle text="Productos escogidos: "/>
                            <div className="table-sm table-response-md">
                                <table className="table w-100 table-sm small">
                                    <thead className="table-info">
                                    <tr>
                                        <th>Producto</th>
                                        <th>Precio</th>
                                        <th>Cantidad</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {productListSelected.items.map((product, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{fontSize: 12}}>{product.name}</td>
                                                <td style={{width: 80, fontSize: 12}}>s./ {product.price}</td>
                                                <td>
                                                    <input
                                                        style={{maxWidth: 50, fontSize: 11, textAlign: 'center'}}
                                                        type="number"
                                                        onChange={(event) => {
                                                            let {target} = event;
                                                            if (target.value > 0 && target.value <= product.stock) {
                                                                onInputQuantityChange(product.id, target.value)
                                                            } else {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                return appAlert.SimpleAlert(`No existe ${target.value} productos en stock para ${product.name}, por el momento tenemos ${product.stock} und.`, 'error');
                                                            }
                                                        }}
                                                        value={product.quantity}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                    <tfoot style={{fontSize: 12}}>
                                    <tr>
                                        <th colSpan={3}>Resumen:</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Monto Total:</td>
                                        <td colSpan={1}>s/ {totalAmount}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Comisión:</td>
                                        <td colSpan={1}>
                                            s/ {Math.round(totalAmount * (paymentSelected.tax ? (paymentSelected.tax / 100) : 0) * 100) / 100}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Monto Final:</td>
                                        <td colSpan={1}>
                                            s/ {Math.round((totalAmount * (1 + (paymentSelected.tax ? parseFloat(paymentSelected.tax / 100) : 0))) * 100) / 100}
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                                <view className='row justify-content-center align-items-center'>
                                    <AppIcon iconName={'exclamation-circle'} color='green'/>
                                    <span className="font-weight-light text-success small">Permite modificar al usuario, las cantidades para cada producto</span>
                                </view>
                            </div>
                            <hr/>
                            <div className="text-center w-100 p-4">
                                <button className="btn btn-primary btn-sm p-2" onClick={CreateSale}>
                                    <div className='row px-3'>
                                        <AppIcon iconName='exclamation-circle' color='white'></AppIcon>
                                        <span>REGISTRAR E IMPRIMIR {isBoleta ? "BOLETA" : "FACTURA"}</span>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div className='row col-md-6'>
                            <div className="col-md-12 shadow p-4 mx-2 form-group">
                                <AppSelect isMulti={true} closeMenuOnSelect={false} label="Productos a escoger: "
                                           onChange={onSetProductListSelected}
                                           errors={['(Sólo se mostrarán los productos con stock y que no hayan vencido)']}
                                           items={productList}/>
                            </div>

                            <div className="col-md-12 shadow text-center p-4 mx-2">
                                <label>Tipo de Comprobante </label>
                                <div className="form-check w-100 d-flex">
                                    {
                                        documentList.items.map((document, index) => {
                                            return (<div key={index} className='form-check w-50 p-2 m-3 border'>
                                                <input
                                                    className="form-check-input" type="radio" name="document_type"
                                                    id={document.value}
                                                    onChange={() => ChangeDocumentType(document)}
                                                    checked={document.checked}
                                                >
                                                </input>
                                                <label className="form-check-label" htmlFor={document.value}>
                                                    {document.label}
                                                </label>
                                            </div>);
                                        })
                                    }
                                </div>
                                <div className="w-100">
                                    <div className="w-100">
                                        <AppSelect
                                            label="Forma de Pago"
                                            onChange={(payment) => setPaymentSelected(payment)}
                                            items={paymentList}
                                            errors={errors.payment_type_id}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div style={{display: "none"}}>
                <PrintPaymentProofBoleta
                    ref={componentRefBoleta}
                    person={person}
                    invoice={invoiceCode}
                    products={productListSelected.items}
                    user={helperSession.getCurrentUser()}
                    payment={paymentSelected}
                    document={documentSelected}
                />
            </div>
            <div style={{display: "none"}}>
                <PrintPaymentProofFactura
                    ref={componentRefFactura}
                    person={person}
                    invoice={invoiceCode}
                    products={productListSelected.items}
                    user={helperSession.getCurrentUser()}
                    payment={paymentSelected}
                    document={documentSelected}
                />
            </div>
            <AppLoading visible={loading}/>
        </div>
    );
}

export default SaleCreateScreen;
