import React, {Component} from "react";
import QRCode from 'react-qr-code'
import AppPaymentBodyForm from "../../components/AppPaymentBodyForm";
import helperNumToText from "../../../helpers/helperNumToText";

export default class PrintPaymentProofBoleta extends Component {
    render() {
        const person = this.props.person ? this.props.person : {};
        const products = this.props.products;
        const user = this.props.user;
        const invoice = this.props.invoice;
        const document = this.props.document;
        const payment = this.props.payment;
        const date = this.props.date;
        const hour = this.props.hour;

        let total = 0;

        return (
            <div className="table w-100" style={{
                width: 8.5,
                height: 13,
                display: "block",

            }}>
                <table className='table-striped w-100 text-left p-5'>
                    <AppPaymentBodyForm person={person} invoice={invoice} user={user} date={date} hour={hour}
                                        document={document} payment={payment}/>
                    <tbody style={{fontSize: 10}}>
                    <tr>
                        <th>PRODUCTO:</th>
                        <th>CANTIDAD:</th>
                        <th>PRECIO:</th>
                        <th>TOTAL:</th>
                    </tr>
                    {
                        products.map((product, index) => {
                            total += parseFloat(product.quantity) * parseFloat(product.price);
                            return (
                                <tr key={index}>
                                    <td>{product.name}</td>
                                    <td>{product.quantity} Und.</td>
                                    <td>s/. {product.price}</td>
                                    <td>s/. {Math.round((parseFloat(product.quantity) * parseFloat(product.price)) * 100) / 100}</td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <th colSpan={4}></th>
                    </tr>
                    <tr>
                        <th colSpan={3}>TOTAL:</th>
                        <td colSpan={1}>s/. {Math.round(total * 100) / 100}</td>
                    </tr>
                    <tr>
                        <th colSpan={3}>COMISIÓN:</th>
                        <td colSpan={1}>
                            s/ {Math.round(total * (payment.tax ? (payment.tax / 100) : 0) * 100) / 100}
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={3}>MONTO FINAL:</th>
                        <td colSpan={1}>
                            s/ {Math.round((total * (1 + (payment.tax ? parseFloat((payment.tax / 100)) : 0))) * 100) / 100}
                        </td>
                    </tr>
                    <tr>
                        <th style={{fontSize: 11, textAlign: "right"}}
                            colSpan={4}>SON {helperNumToText.ChangePriceToStringFormat(Math.round((total * (1 + (payment.tax ? parseFloat((payment.tax / 100)) : 0))) * 100) / 100)}</th>
                    </tr>
                    <tr>
                        <td className='text-center' colSpan={4}>
                            <QRCode value={invoice ? invoice : ''}/>
                        </td>
                    </tr>
                    <tr className='text-center'>
                        <td colSpan={4}> Representación referencial e impresa de la
                            Boleta De Venta Electrónica (Sin valor para SUNAT)
                        </td>
                    </tr>
                    <tr className='text-center'>
                        <th colSpan={4}>
                            Gracias por su preferencia, vuelva pronto.
                        </th>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
