import React, { useEffect, useState } from "react";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";
import AppLoading from "../../common/AppLoading";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../common/AppBreadCrumb";
import appColors from "../../../config/appColors";
import AppAlert from "../../common/AppAlert";
import apiSale from "../../../apis/sale/apiSale";

function ListSaleScreen() {
	const headers = ["Fecha", "Persona", "Usuario"];
	const values = ["saled_date", "person_names", "user_email"];
	const buttons = [
		{ onClick: (sale) => DetailSelectedSale(sale), style: "btn", iconName: "eye", iconColor: appColors.blue_04 },
		{ onClick: (sale) => ShowDeleteSelectedSale(sale), style: "btn", iconName: "trash-alt", iconColor: appColors.red_04 },
	];

	const history = useHistory();
	const [filter, setFilter] = useState("");
	const [paginator, setPaginator] = useState({});
	const [saleList, setSaleList] = useState([]);
	const [loading, setLoading] = useState(false);

	const GetSales = async (page = 1) => {
		setLoading(true);
		var response = await apiSale.getSalesPaginated(page, filter);
		setLoading(false);

		if (response.ok) {
			setPaginator(response.data.paginator);
			setSaleList(response.data.results);
		}
	};

	const EditSelectedSale = (sale) => {
		history.push({ pathname: "/sale/edit", state: sale });
	};

	const DetailSelectedSale = (sale) => {
		history.push({ pathname: "/sale/detail", state: sale });
	};

	const ShowDeleteSelectedSale = (sale) => {
		AppAlert.OptionsAlert(
			"Esta venta se eliminará, y ya no estará disponible para el resto de operaciones, y se eliminará toda su información.",
			"Eliminar de todos modos"
		).then((response) => {
			if (response) {
				DeleteSale(sale.id);
			}
		});
	};

	const DeleteSale = async (sale_id) => {
		var response = await apiSale.deleteSale(sale_id);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			GetSales();
		}
	};

	useEffect(() => {
		GetSales();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-10">
				<AppTitle text="Lista de Ventas Registradas:" />
				<BreadCrumb items={[{ label: "Ventas" }, { label: "Lista de Ventas", active: true }]} />
			</div>
			<div className="col-12">
				<TablePaginate
					headers={headers}
					buttons={buttons}
					rows={saleList}
					values={values}
					onSearchApi={(page) => GetSales(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
				/>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default ListSaleScreen;
