import React from "react";
import moment from "moment";

function AppPaymentBodyForm({person, user, document, payment, invoice, date, hour}) {

    return (
        <thead className='text-center' style={{fontSize: 12}}>
        <tr>
            <th colSpan={4}>
                <img src="../imgs/logo-VIC.jpg" style={{width: '30%', alignSelf: "center"}}/>
            </th>
        </tr>
        <tr>
            <th colSpan={4}>
                {user.dinner_name.toString().toUpperCase()}
            </th>
        </tr>
        <tr>
            <td colSpan={4}>
                RUC: {user.dinner_code ? user.dinner_code : ' - '}
            </td>
        </tr>
        <tr>
            <td colSpan={4}>
                DIRECCIÓN: {user.dinner_address ? user.dinner_address : ' - '}
            </td>
        </tr>
        <tr>
            <td colSpan={4}>
                CAJAMARCA - CAJAMARCA - CAJAMARCA
            </td>
        </tr>
        <tr>
            <td colSpan={4}>
                TELÉFONO: {user.dinner_phone ? user.dinner_phone : ' - '}
            </td>
        </tr>
        <tr>
            <th colSpan={4}>
                {`COMPROBANTE DE ${document?.label?.toString().toUpperCase()} DE VENTA ELECTRÓNICA`}
            </th>
        </tr>
        <tr>
            <td colSpan={4}>
                No: {invoice}
            </td>
        </tr>
        <tr>
            <td className='text-right w-50' colSpan={2}>
                FECHA:
            </td>
            <td className='text-left w-50' colSpan={2}>
                {date ? date : moment().format('DD/MM/YYYY')}
            </td>
        </tr>
        <tr>
            <td className='text-right w-50' colSpan={2}>
                HORA:
            </td>
            <td className='text-left w-50' colSpan={2}>
                {hour ? hour : moment().format('hh:mm a')}
            </td>
        </tr>
        <tr>
            <td className='text-right w-50' colSpan={2}>
                CAJERO:
            </td>
            <td className='text-left w-50' colSpan={2}>
                {user.email}
            </td>
        </tr>
        <tr>
            <td className='text-right w-50' colSpan={2}>
                CLIENTE:
            </td>
            <td className='text-left w-50' colSpan={2}>
                {person.first_names} {person.last_names}
            </td>
        </tr>
        <tr>
            <td className='text-right w-50' colSpan={2}>
                DNI:
            </td>
            <td className='text-left w-50' colSpan={2}>
                {person.dni}
            </td>
        </tr>
        <tr>
            <td className='text-right w-50' colSpan={2}>
                RUC:
            </td>
            <td className='text-left w-50' colSpan={2}>
                {person.ruc}
            </td>
        </tr>
        <tr>
            <td className='text-right w-50' colSpan={2}>
                DIRECCIÓN:
            </td>
            <td className='text-left w-50' colSpan={2}>
                {person.address}
            </td>
        </tr>
        <tr>
            <td className='text-right w-50' colSpan={2}>
                TIPO DE PAGO:
            </td>
            <td className='text-left w-50' colSpan={2}>
                {payment.label}
            </td>
        </tr>
        <tr>
            <td className='text-right w-50' colSpan={4}/>
        </tr>
        </thead>
    );
}

export default AppPaymentBodyForm;
