import React from "react";
import schema from "../config/schema";
import Header from "./common/AppHeader";
import {Route, Switch} from "react-router";
import AttendanceExtempScreen from "./screens/attendance/AttendanceExtempScreen";
import AttendanceScreen from "./screens/attendance/AttendanceScreen";
import ListAttendancesScreen from "./screens/attendance/ListAttendancesScreen";
import ListContractorsScreen from "./screens/contractor/ListContractorsScreen";
import DinnerCreateForm from "./screens/dinner/DinnerCreateScreen";
import DinnerEditScreen from "./screens/dinner/DinnerEditScreen";
import ListDinnersScreen from "./screens/dinner/ListDinnersScreen";
import FormsScreen from "./screens/forms/FormsScreen";
import MeterWithdrawalAct from "./screens/forms/MeterWithdrawalAct";
import MeterWithdrawalNotification from "./screens/forms/MeterWithdrawalNotification";
import AttendanceReportScreen from "./screens/reports/AttendanceReportScreen";
import ListServicesScreen from "./screens/service/ListServicesScreen";
import UserCreateScreen from "./screens/user/UserCreateScreen";
import ListWorkUnitsScreen from "./screens/workUnit/ListWorkUnitsScreen";
import DashboardScreen from "./screens/dashboard/DashboardScreen";
import Footer from "./common/AppFooter";
import ContractorEditScreen from "./screens/contractor/ContractorEditScreen";
import ContractorCreateScreen from "./screens/contractor/ContractorCreateScreen";
import ServiceEditScreen from "./screens/service/ServiceEditScreen";
import ServiceCreateScreen from "./screens/service/ServiceCreateScreen";
import WorkUnitCreateScreen from "./screens/workUnit/WorkUnitCreateScreen";
import WorkUnitEditScreen from "./screens/workUnit/WorkUnitEditScreen";
import SaleCreateScreen from "./screens/sale/SaleCreateScreen";
import ListSaleScreen from "./screens/sale/ListSaleScreen";
import SupplyCreateScreen from "./screens/supply/SupplyCreateScreen";
import SupplyEditScreen from "./screens/supply/SupplyEditScreen";
import ListSuppliesScreen from "./screens/supply/ListSuppliesScreen";
import SaleEditScreen from "./screens/sale/SaleEditScreen";
import SaleDetailScreen from "./screens/sale/SaleDetailScreen";
import UserAttendancesHistory from "./screens/dashboard/UserAttendancesHistory";
import NotificatePersonScreen from "./screens/notifications/NotificatePersonScreen";
import ListPersonsNotifications from "./screens/notifications/ListPersonsNotificationScreen";
import ListPersonsInstalationScreen from "./screens/instalations/ListPersonsInstalationScreen";
import InstalatePersonScreen from "./screens/instalations/InstalatePersonScreen";
import SedacajReportsScreen from "./screens/reports/SedacajReportsScreen";
import SaleReportScreen from "./screens/reports/SaleReportScreen";
import AssignmentScreen from "./screens/assignment/AssignmentScreen";
import AssignmentCreateScreen from "./screens/assignment/AssignmentCreateScreen";
import AssignateScreen from "./screens/assignment/AssignateScreen";
import AssignationsVerifyScreen from "./screens/assignment/AssignationsVerifyScreen";
import ObservationPersonScreen from "./screens/person/ObservationPersonScreen";
import MeasurerCreateScreen from "./screens/measurer/MeasurerCreateScreen";
import ReportMeasurerScreen from "./screens/measurer/ReportMeasurerScreen";
import GenericCreateFormScreen from "./screens/GENERICS/GenericCreateFormScreen";
import GenericListFormScreen from "./screens/GENERICS/GenericListFormScreen";
import GenericEditFormScreen from "./screens/GENERICS/GenericEditFormScreen";
import SaleDashboardsScreen from "./screens/graphics/SaleDashboardsScreen";
import ProductDashboardsScreen from "./screens/graphics/ProductDashboardsScreen";
import ProductReportScreen from "./screens/reports/ProductReportScreen";


function HomeScreen({userLogged = {}}) {
    const {user} = userLogged;

    const menu_customer_01_and_rol_01 = [
        {
            title: "Reportes",
            icon: "file-invoice",
            submenu: [
                {icon: "file-invoice", label: "Reporte de Ventas", route: "/sale-report"},
                {icon: "capsules", label: "Reporte de Productos", route: "/product-report"},
            ],
        },
        {
            title: "Gráficos",
            icon: "chart-line",
            submenu: [
                {icon: "chart-pie", label: "Gráfico de Ventas", route: "/sale-dashboard-report"},
                {icon: "chart-bar", label: "Gráfico de Productos", route: "/product-dashboard-report"},
            ],
        },
        {
            title: "Clientes",
            icon: "male",
            submenu: [
                {icon: "male", label: "Registro de Clientes", route: "/person"},
                {icon: "list-ul", label: "Lista de Clientes", route: "/person/list"},
            ],
        },
        {
            title: "Usuarios",
            icon: "user-nurse",
            submenu: [
                {icon: "user-nurse", label: "Registro de Usuarios", route: "/user"},
                {icon: "list-ul", label: "Lista de Usuarios", route: "/user/list"},
            ],
        },
        {
            title: "Ventas",
            icon: "cash-register",
            submenu: [
                {icon: "cash-register", label: "Registro De Venta", route: "/sale"},
                {icon: "list-ul", label: "Lista de Ventas", route: "/sale/list"},
            ],
        },
        {
            title: "Productos",
            icon: "capsules",
            submenu: [
                {icon: "capsules", label: "Registro Productos", route: "/product"},
                {icon: "list-ul", label: "Lista de Productos", route: "/product/list"},
            ],
        },
        {
            title: "Extras",
            icon: "stethoscope",
            submenu: [
                {icon: "box-open", label: "Categoria Producto", route: "/category-product"},
                {icon: "pump-medical", label: "Presentacion Producto", route: "/presentation-product"},
                {icon: "vial", label: "Laboratorio Producto", route: "/laboratory-product"},
                {icon: "briefcase", label: "Puntos de Servicio", route: "/dinner-point/"},
                {icon: "code-branch", label: "Sucursales", route: "/dinner/"},
                {icon: "code-branch", label: "Crear Sucursal", route: "/dinner/create/"},
                {icon: "credit-card", label: "Forma de Pago", route: "/payments"}
            ],
        },
    ];

    const menu_customer_01_and_rol_02 = [
        {
            title: "Reportes",
            icon: "file-invoice",
            submenu: [
                {icon: "file-invoice", label: "Reporte de Ventas", route: "/sale-report"},
                {icon: "capsules", label: "Reporte de Productos", route: "/sale-report"},
            ],
        },
        {
            title: "Clientes",
            icon: "male",
            submenu: [
                {icon: "male", label: "Registro de Clientes", route: "/person"},
                {icon: "list-ul", label: "Lista de Clientes", route: "/person/list"},
            ],
        },
        {
            title: "Ventas",
            icon: "cash-register",
            submenu: [
                {icon: "cash-register", label: "Registro De Venta", route: "/sale"},
                {icon: "list-ul", label: "Lista de Ventas", route: "/sale/list"},
            ],
        },
        {
            title: "Productos",
            icon: "capsules",
            submenu: [
                {icon: "capsules", label: "Registro Productos", route: "/product"},
                {icon: "list-ul", label: "Lista de Productos", route: "/product/list"},
            ],
        },
    ];

    const GetCustomerMenu = () => {
        switch (user.customer_code) {
            case "01":
                if (user.rol_code == "01") return menu_customer_01_and_rol_01;
                else if (user.rol_code == "02") return menu_customer_01_and_rol_02;
                break;
            default:
                return [];
        }
        return menu_customer_01_and_rol_01;
    };

    const ViewContainer = ({children}) => {
        return (
            <div className='container'>
                {children}
            </div>
        )
    }

    return (
        <div className="container-fluid">
            <Header menu={GetCustomerMenu()} user={user}/>
            <Switch>
                <Route path="/attendance/create">
                    <ViewContainer>
                        <AttendanceScreen/>
                    </ViewContainer>
                </Route>
                <Route path="/attendance/extemp">
                    <ViewContainer><AttendanceExtempScreen/></ViewContainer>
                </Route>
                <Route path="/attendance">
                    <ViewContainer><ListAttendancesScreen/></ViewContainer>
                </Route>
                <Route path="/user/list">
                    <ViewContainer>
                        <GenericListFormScreen
                            moduleName={schema.user.name}
                            showCreateButton={false}
                            urlItems={schema.user.allURL}
                            urlDelete={schema.user.deleteURL}
                            routeEditComponent={schema.user.redirect.editComponent}
                            routeCreateComponent={schema.user.redirect.createComponent}
                            tableHeaders={schema.user.table.headers}
                            tableValues={schema.user.table.values}
                            tableButtons={schema.user.table.buttons}/>
                    </ViewContainer>
                </Route>
                <Route path="/user/edit">
                    <ViewContainer><GenericEditFormScreen
                        editURL={schema.user.updateURL}
                        detailURL={schema.user.detailURL}
                        moduleName={schema.user.name}
                        registerProperties={schema.user.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/user">
                    <ViewContainer><UserCreateScreen/></ViewContainer>
                </Route>
                <Route path="/person/list">
                    <ViewContainer><GenericListFormScreen
                        moduleName={schema.person.name}
                        showCreateButton={false}
                        urlItems={schema.person.allURL}
                        urlDelete={schema.person.deleteURL}
                        routeEditComponent={schema.person.redirect.editComponent}
                        routeCreateComponent={schema.person.redirect.createComponent}
                        tableHeaders={schema.person.table.headers}
                        tableValues={schema.person.table.values}
                        tableButtons={schema.person.table.buttons}/></ViewContainer>
                </Route>
                <Route path="/person/edit">
                    <ViewContainer><GenericEditFormScreen
                        editURL={schema.person.updateURL}
                        detailURL={schema.person.detailURL}
                        moduleName={schema.person.name}
                        registerProperties={schema.person.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/person/notification/list">
                    <ViewContainer><ListPersonsNotifications/></ViewContainer>
                </Route>
                <Route path="/person/notification">
                    <ViewContainer><NotificatePersonScreen/></ViewContainer>
                </Route>
                <Route path="/person/observation">
                    <ViewContainer> <ObservationPersonScreen/></ViewContainer>
                </Route>
                <Route path="/person/instalation/list">
                    <ViewContainer><ListPersonsInstalationScreen/></ViewContainer>
                </Route>
                <Route path="/person/instalation">
                    <ViewContainer><InstalatePersonScreen/></ViewContainer>
                </Route>
                <Route path="/person">
                    <ViewContainer> <GenericCreateFormScreen
                        createURL={schema.person.createURL}
                        moduleName={schema.person.name}
                        registerProperties={schema.person.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/dinner/create">
                    <ViewContainer><DinnerCreateForm/></ViewContainer>
                </Route>
                <Route path="/dinner/edit">
                    <ViewContainer><DinnerEditScreen/></ViewContainer>
                </Route>
                <Route path="/dinner">
                    <ViewContainer><ListDinnersScreen/></ViewContainer>
                </Route>
                {/*<Route path="/dinner-point/create">*/}
                {/*    <DinnerPointCreateForm/>*/}
                {/*</Route>*/}
                {/*<Route path="/dinner-point/edit">*/}
                {/*    <DinnerPointEditForm/>*/}
                {/*</Route>*/}
                {/*<Route path="/dinner-point">*/}
                {/*    <ListDinnerPointsScreen/>*/}
                {/*</Route>*/}

                {/* PUNTO DE SERVICIO */}
                <Route path="/dinner-point/create">
                    <ViewContainer><GenericCreateFormScreen
                        createURL={schema.dinner_point.createURL}
                        moduleName={schema.dinner_point.name}
                        registerProperties={schema.dinner_point.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/dinner-point/edit">
                    <ViewContainer><GenericEditFormScreen
                        editURL={schema.dinner_point.updateURL}
                        detailURL={schema.dinner_point.detailURL}
                        moduleName={schema.dinner_point.name}
                        registerProperties={schema.dinner_point.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/dinner-point">
                    <ViewContainer><GenericListFormScreen
                        moduleName={schema.dinner_point.name}
                        urlItems={schema.dinner_point.allURL}
                        urlDelete={schema.dinner_point.deleteURL}
                        routeEditComponent={schema.dinner_point.redirect.editComponent}
                        routeCreateComponent={schema.dinner_point.redirect.createComponent}
                        tableHeaders={schema.dinner_point.table.headers}
                        tableValues={schema.dinner_point.table.values}
                        tableButtons={schema.dinner_point.table.buttons}
                    /></ViewContainer>
                </Route>
                <Route path="/print-forms/withdrawal-notification">
                    <ViewContainer><MeterWithdrawalNotification/></ViewContainer>
                </Route>
                <Route path="/print-forms/withdrawal-act">
                    <ViewContainer><MeterWithdrawalAct/></ViewContainer>
                </Route>
                <Route path="/print-forms">
                    <ViewContainer><FormsScreen/></ViewContainer>
                </Route>
                <Route path="/attendance-report">
                    <ViewContainer><AttendanceReportScreen/></ViewContainer>
                </Route>
                <Route path="/sedacaj-report">
                    <ViewContainer><SedacajReportsScreen/></ViewContainer>
                </Route>
                <Route path="/sale-report">
                    <ViewContainer><SaleReportScreen/></ViewContainer>
                </Route>
                <Route path="/product-report">
                    <ViewContainer><ProductReportScreen/></ViewContainer>
                </Route>
                <Route path="/sale-dashboard-report">
                    <ViewContainer><SaleDashboardsScreen/></ViewContainer>
                </Route>
                <Route path="/product-dashboard-report">
                    <ViewContainer><ProductDashboardsScreen/></ViewContainer>
                </Route>
                <Route path="/assignment/list">
                    <ViewContainer><AssignmentScreen/></ViewContainer>
                </Route>
                <Route path="/assignment/create">
                    <ViewContainer><AssignmentCreateScreen/></ViewContainer>
                </Route>
                <Route path="/assignment/assignate">
                    <ViewContainer><AssignateScreen/></ViewContainer>
                </Route>
                <Route path="/assignment/verify">
                    <ViewContainer><AssignationsVerifyScreen/></ViewContainer>
                </Route>
                <Route path="/service/create">
                    <ViewContainer><ServiceCreateScreen/></ViewContainer>
                </Route>
                <Route path="/service/edit">
                    <ViewContainer><ServiceEditScreen/></ViewContainer>
                </Route>
                <Route path="/service">
                    <ViewContainer><ListServicesScreen/></ViewContainer>
                </Route>
                <Route path="/work-unit/create">
                    <ViewContainer><WorkUnitCreateScreen/></ViewContainer>
                </Route>
                <Route path="/work-unit/edit">
                    <ViewContainer><WorkUnitEditScreen/></ViewContainer>
                </Route>
                <Route path="/work-unit">
                    <ViewContainer><ListWorkUnitsScreen/></ViewContainer>
                </Route>
                <Route path="/contractor/edit">
                    <ViewContainer><ContractorEditScreen/></ViewContainer>
                </Route>
                <Route path="/contractor/create">
                    <ViewContainer><ContractorCreateScreen/></ViewContainer>
                </Route>
                <Route path="/contractor">
                    <ViewContainer><ListContractorsScreen/></ViewContainer>
                </Route>
                <Route path="/supply/list">
                    <ViewContainer><ListSuppliesScreen/></ViewContainer>
                </Route>
                <Route path="/supply/edit">
                    <ViewContainer><SupplyEditScreen/></ViewContainer>
                </Route>
                <Route path="/supply">
                    <ViewContainer><SupplyCreateScreen/></ViewContainer>
                </Route>
                {/* PRODUCT */}
                <Route path="/product/list">
                    <ViewContainer><GenericListFormScreen
                        moduleName={schema.product.name}
                        showCreateButton={false}
                        urlItems={schema.product.allURL}
                        urlDelete={schema.product.deleteURL}
                        routeEditComponent={schema.product.redirect.editComponent}
                        routeCreateComponent={schema.product.redirect.createComponent}
                        tableHeaders={schema.product.table.headers}
                        tableValues={schema.product.table.values}
                        tableButtons={schema.product.table.buttons}
                    /></ViewContainer>
                </Route>
                <Route path="/product/edit">
                    <ViewContainer><GenericEditFormScreen
                        editURL={schema.product.updateURL}
                        detailURL={schema.product.detailURL}
                        moduleName={schema.product.name}
                        registerProperties={schema.product.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/product">
                    <ViewContainer><GenericCreateFormScreen
                        createURL={schema.product.createURL}
                        moduleName={schema.product.name}
                        registerProperties={schema.product.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/sale/list">
                    <ViewContainer><ListSaleScreen/></ViewContainer>
                </Route>
                <Route path="/sale/edit">
                    <ViewContainer><SaleEditScreen/></ViewContainer>
                </Route>
                <Route path="/sale/detail">
                    <ViewContainer><SaleDetailScreen/></ViewContainer>
                </Route>
                <Route path="/sale">
                    <ViewContainer><SaleCreateScreen/></ViewContainer>
                </Route>
                <Route path="/user-attendances">
                    <ViewContainer><UserAttendancesHistory/></ViewContainer>
                </Route>
                <Route path="/measurer/register">
                    <ViewContainer><MeasurerCreateScreen/></ViewContainer>
                </Route>
                <Route path="/measurer">
                    <ViewContainer><ReportMeasurerScreen/></ViewContainer>
                </Route>
                {/* CATEGORY DEL PRODUCTO */}
                <Route path="/category-product/create">
                    <ViewContainer><GenericCreateFormScreen
                        createURL={schema.category_product.createURL}
                        moduleName={schema.category_product.name}
                        registerProperties={schema.category_product.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/category-product/edit">
                    <ViewContainer><GenericEditFormScreen
                        editURL={schema.category_product.updateURL}
                        detailURL={schema.category_product.detailURL}
                        moduleName={schema.category_product.name}
                        registerProperties={schema.category_product.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/category-product">
                    <ViewContainer><GenericListFormScreen
                        moduleName={schema.category_product.name}
                        urlItems={schema.category_product.allURL}
                        urlDelete={schema.category_product.deleteURL}
                        routeEditComponent={schema.category_product.redirect.editComponent}
                        routeCreateComponent={schema.category_product.redirect.createComponent}
                        tableHeaders={schema.category_product.table.headers}
                        tableValues={schema.category_product.table.values}
                        tableButtons={schema.category_product.table.buttons}
                    /></ViewContainer>
                </Route>
                {/* PRESENTATION DEL PRODUCTO */}
                <Route path="/presentation-product/create">
                    <ViewContainer><GenericCreateFormScreen
                        createURL={schema.presentation_product.createURL}
                        moduleName={schema.presentation_product.name}
                        registerProperties={schema.presentation_product.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/presentation-product/edit">
                    <ViewContainer><GenericEditFormScreen
                        editURL={schema.presentation_product.updateURL}
                        detailURL={schema.presentation_product.detailURL}
                        moduleName={schema.presentation_product.name}
                        registerProperties={schema.presentation_product.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/presentation-product">
                    <ViewContainer><GenericListFormScreen
                        moduleName={schema.presentation_product.name}
                        urlItems={schema.presentation_product.allURL}
                        urlDelete={schema.presentation_product.deleteURL}
                        routeEditComponent={schema.presentation_product.redirect.editComponent}
                        routeCreateComponent={schema.presentation_product.redirect.createComponent}
                        tableHeaders={schema.presentation_product.table.headers}
                        tableValues={schema.presentation_product.table.values}
                        tableButtons={schema.presentation_product.table.buttons}
                    /></ViewContainer>
                </Route>
                {/* LABORATORY DEL PRODUCTO */}
                <Route path="/laboratory-product/create">
                    <ViewContainer><GenericCreateFormScreen
                        createURL={schema.laboratory_product.createURL}
                        moduleName={schema.laboratory_product.name}
                        registerProperties={schema.laboratory_product.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/laboratory-product/edit">
                    <ViewContainer><GenericEditFormScreen
                        editURL={schema.laboratory_product.updateURL}
                        detailURL={schema.laboratory_product.detailURL}
                        moduleName={schema.laboratory_product.name}
                        registerProperties={schema.laboratory_product.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/laboratory-product">
                    <ViewContainer><GenericListFormScreen
                        moduleName={schema.laboratory_product.name}
                        urlItems={schema.laboratory_product.allURL}
                        urlDelete={schema.laboratory_product.deleteURL}
                        routeEditComponent={schema.laboratory_product.redirect.editComponent}
                        routeCreateComponent={schema.laboratory_product.redirect.createComponent}
                        tableHeaders={schema.laboratory_product.table.headers}
                        tableValues={schema.laboratory_product.table.values}
                        tableButtons={schema.laboratory_product.table.buttons}
                    /></ViewContainer>
                </Route>
                {/* PAYMENT DE LA VENTA */}
                <Route path="/payments/create">
                    <ViewContainer><GenericCreateFormScreen
                        createURL={schema.payment_types.createURL}
                        moduleName={schema.payment_types.name}
                        registerProperties={schema.payment_types.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/payments/edit">
                    <ViewContainer><GenericEditFormScreen
                        editURL={schema.payment_types.updateURL}
                        detailURL={schema.payment_types.detailURL}
                        moduleName={schema.payment_types.name}
                        registerProperties={schema.payment_types.properties}
                    /></ViewContainer>
                </Route>
                <Route path="/payments">
                    <ViewContainer><GenericListFormScreen
                        moduleName={schema.payment_types.name}
                        urlItems={schema.payment_types.allURL}
                        urlDelete={schema.payment_types.deleteURL}
                        routeEditComponent={schema.payment_types.redirect.editComponent}
                        routeCreateComponent={schema.payment_types.redirect.createComponent}
                        tableHeaders={schema.payment_types.table.headers}
                        tableValues={schema.payment_types.table.values}
                        tableButtons={schema.payment_types.table.buttons}
                    /></ViewContainer>
                </Route>
                <Route path="/">
                    <DashboardScreen/>
                </Route>
            </Switch>
            <div style={{
                color: '#fff',
                textAlign: 'center',
                padding: '5px',
                position: 'sticky',
                width: '98vw',
                bottom: 0,
            }}>
                <Footer/>
            </div>
        </div>
    );
}

export default HomeScreen;
