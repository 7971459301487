import React, { useEffect, useState } from "react";
import apiCompany from "../../../apis/company/apiCompany";
import apiDinner from "../../../apis/dinner/apiDinner";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";

function DinnerCreateForm() {
	const [name, setName] = useState("");
	const [company, setCompany] = useState({});
	const [loading, setLoading] = useState(false);
	const [companies, setCompanies] = useState([]);
	const [errorsForm, setErrorsForm] = useState({});

	const CreateDinner = async () => {
		const dinner = {
			name: name,
			company_id: company.value,
		};

		setLoading(true);
		const response = await apiDinner.createDinner(dinner);
		setLoading(false);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			setErrorsForm({});
		} else {
			setErrorsForm(response);
		}
	};

	const GetCompanies = async () => {
		const response = await apiCompany.getCompaniesDropdown();

		if (response.ok) {
			setCompanies(response.data);
		}
	};

	useEffect(() => {
		GetCompanies();
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Comedor" />
				<BreadCrumb items={[{ label: "Comedores" }, { label: "Registro de Comedores", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-6">
						<AppInput
							label="Nombre de la sucursal"
							type="text"
							onChange={({ target }) => setName(target.value)}
							placeholder="Nombre de la sucursal"
							errors={errorsForm.name}
						/>
					</div>
					<div className="col-6">
						<AppSelect label="Compañía" items={companies} onChange={(dinner) => setCompany(dinner ? dinner : {})} errors={errorsForm.company_id} />
					</div>
					<div className="col-12 text-center my-4">
						<button className="btn btn-primary" onClick={CreateDinner}>
							Crear nuevo registro
						</button>
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default DinnerCreateForm;
